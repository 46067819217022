/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {
  function listener(){
    index = slider_flick_data.selectedIndex;
    $('.animate-slider h2').removeClass(h2_class);
    $('.animate-slider p').removeClass(p_class);

    $('.animate-slider .is-selected h2').addClass(h2_class);
    $('.animate-slider .is-selected p').addClass(p_class);
  }

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
		function preload_site(){
			$("#preloader").fadeOut("fast");
  			$("body").removeClass("preload");
		}
		//preload_site();

		//functions
        $(document).foundation(); // Foundation JavaScript

        WebFontConfig = {
          google: { families: [ 'Open+Sans:400,300,600,700:latin,latin-ext', 'Raleway:400,500,700,300,800:latin,latin-ext' ] }
        };
        (function() {
          var wf = document.createElement('script');
          wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
          wf.type = 'text/javascript';
          wf.async = 'true';
          var s = document.getElementsByTagName('script')[0];
          s.parentNode.insertBefore(wf, s);
        })();

        var header_height = $("#header").height();
        var scroll = jQuery(document).scrollTop();
        if(scroll>header_height){
           jQuery('.bottomheader').addClass('headerfixed');
           jQuery('.mobile-menu').addClass('margin');
        } else {
           jQuery('.bottomheader').removeClass('headerfixed');
           jQuery('.mobile-menu').removeClass('margin');
        }

        jQuery( window ).scroll(function() {
          var header_height = $("#header").height();
          var scroll = jQuery(document).scrollTop();
          if(scroll>header_height){
             jQuery('.bottomheader').addClass('headerfixed');
             jQuery('.mobile-menu').addClass('margin');
          } else {
             jQuery('.bottomheader').removeClass('headerfixed');
             jQuery('.mobile-menu').removeClass('margin');
          }
        });

        $('.mobilemenu-btn').click(function(){
          $(this).toggleClass('open');
        });

        $(".mobile-menu").hide();
        $(".mobilemenu-btn").click(function(){
          $("body").toggleClass("preload");
          $(".mobile-menu").slideToggle("normal");
          return false;
        });

		var filterOnLoad = window.location.hash ? '.'+(window.location.hash).substr(1) : '.category-all';
        $('#portfolio').mixItUp({
          animation: {
            animateResizeContainer: false
          },
		  load: {
			  filter: filterOnLoad
		  },
          callbacks: {
              onMixEnd: function(state){
                  var portfolioEq = new Foundation.Equalizer($("#portfolio"));
                  portfolioEq.applyHeight();
              }
          }
        });


		if( typeof lektor !== 'undefined' && lektor !== '' ){
			$('.lektor select').val( lektor ).prop( 'readonly', true );
			$('.lektor option:not(:selected)').hide();
		} else {
			var foundin = $('option:contains("optgroup-")');
			$.each(foundin, function(value){
			  var updated = $(this).val().replace('optgroup-','');
			  $(this).nextUntil('option:contains("endoptgroup")').wrapAll('<optgroup label="'+updated+'"></optgroup>');
			});
			$('option:contains("optgroup-")').remove();
			$('option:contains("endoptgroup")').remove();
		}

        $('.image-link').magnificPopup({type:'image'});

        $('.open-text-popup').magnificPopup({
          type:'inline',
          midClick: true // Allow opening popup on middle mouse click. Always set it to true if you don't provide alternative source in href.
        });

        var wow = new WOW(
          {
            mobile: false
          }
        );
        wow.init();

        imagesLoaded( '.animate-slider', function() {
            slider_flick = $('.animate-slider').flickity({
              cellAlign: 'left',
              contain: true,
              wrapAround: true,
              pageDots: false,
              autoPlay: 6800
            });
            slider_flick_data = slider_flick.data('flickity');

            slider_flick.on('cellSelect', listener);
        });
        h2_class = $('.animate-slider .gallery-cell:eq(0) h2').data('class');
        p_class = $('.animate-slider .gallery-cell:eq(0) p').data('class');

        $('.animate-slider .gallery-cell:eq(0) h2').addClass(h2_class);
        $('.animate-slider .gallery-cell:eq(0) p').addClass(p_class);

        $('.small-slider').flickity({
            cellAlign: 'left',
            contain: true,
            wrapAround: true,
            pageDots: false,
            autoPlay: false
        });

        $('.quotes-slider').flickity({
            cellAlign: 'center',
            contain: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
            autoPlay: true
        });

        $('.lectors-slider').flickity({
            cellAlign: 'center',
            contain: true,
            wrapAround: true,
            pageDots: false,
            autoPlay: false
        });

        $('.work-slider').flickity({
            cellAlign: 'center',
            contain: true,
            wrapAround: true,
            prevNextButtons: false,
            pageDots: true,
            autoPlay: false
        });

        $(".search-more").click(function(){
          var $this = $(this);
          $(".search-form .search-hide").fadeToggle('fast');

          $this.toggleClass('search-show');

          if($this.hasClass('search-show')){
            $this.html('<i class="fa fa-search"></i> Ukryj wyszukiwarkę &raquo;');
          } else {
            $this.html('<i class="fa fa-search"></i> Wyszukiwarka zaawansowana &raquo;');
          }
          return false;
        });

        $(".box-lektor .koszyk").click(function(e){
          var okno = $(this).parent('.buttons').next('.lektor-cloud');
          $('.lektor-cloud:visible').not(okno).hide();

          if(okno.is(':visible')){
            okno.fadeOut('fast');
          } else {
            okno.fadeIn('fast');
          }

          e.stopPropagation();
        });


		//lazy load mp3
		$(document).on( 'click','.player.notloaded a',function() {
			var player = $(this).parent();
			var data = {
			    action: 'shortcode_on_click',
				audiosrc: player.data('src')
			};
			player.html('<a href="#" class="button redbg border"><i class="fa fa-cog fa-spin" aria-hidden="true"></i> Wczytuję</a>');
			$.post( ajax_url, data, function(response) {
				player.removeClass( 'notloaded' ).html(response);
				//console.log( response );
			});
			return false;
		});

        $(document).on('click',function(e){
          $('.lektor-cloud:visible').hide();
          e.stopPropagation();
        });

	$("a[href*='#']").parent('li').removeClass('current-menu-item');

		if( (window.location.hash).substr(1) !== '' ){
			$("a[href*='"+(window.location.hash).substr(1)+"']").parent('li').addClass('current-menu-item');
		}

		$("a[href*='#']").click(function(e) {
			var test = $(this).prop("hash");
			var filter = '.'+test.substr(1);
			//console.log( filter );
			$('#portfolio').mixItUp('filter', filter);
			$("a[href*='#']").parent('li').removeClass('current-menu-item');
			$(this).parent('li').addClass('current-menu-item');
          	//e.preventDefault();
        });

	var recaptcha1;
        var recaptcha2;
        var recaptcha3;

		if( $('#recaptcha1').length > 0 ) {
          recaptcha1 = grecaptcha.render('recaptcha1', {
            'sitekey' : '6LdjQyATAAAAAJjOkmoG0qJpThxDhXQrDpLBE3u2', //Replace this with your Site key
            'theme' : 'light',
            'size' : 'compact'
          });
	    }
		if( $('#recaptcha2').length > 0 ) {
          recaptcha2 = grecaptcha.render('recaptcha2', {
            'sitekey' : '6LdjQyATAAAAAJjOkmoG0qJpThxDhXQrDpLBE3u2', //Replace this with your Site key
            'theme' : 'dark'
          });
	    }
		if( $('#recaptcha3').length > 0 ) {
          recaptcha3 = grecaptcha.render('recaptcha3', {
            'sitekey' : '6LdjQyATAAAAAJjOkmoG0qJpThxDhXQrDpLBE3u2', //Replace this with your Site key
            'theme' : 'light',
            'size' : 'compact'
          });
	    }


		//o_nas_desktop.children('a').next('ul').children('li').removeClass('current-menu-item');
    	//o_nas_mobile.children('a').next('ul').children('li').removeClass('current-menu-item');

      },
      finalize: function() {
		 (function(d, s, id) {
  		  var js, fjs = d.getElementsByTagName(s)[0];
  		  if (d.getElementById(id)) return;
  		  js = d.createElement(s); js.id = id;
  		  js.src = "//connect.facebook.net/pl_PL/sdk.js#xfbml=1&version=v2.6&appId=383569965023361";
  		  fjs.parentNode.insertBefore(js, fjs);
  		}(document, 'script', 'facebook-jssdk'));
		
		$('[data-reveal]').on('closed.zf.reveal', function() {
			$(this).find( 'iframe' ).each( function (){
				$(this).remove();
			});
			$('video, audio').each(function() {
			      $(this)[0].pause();
			});
		});

		$('[data-reveal]').on('open.zf.reveal', function() {
			$(this).find( '.iframe_load' ).each( function (){
			  var iframe = $(this).data( 'iframe' );
			  $(this).html( iframe );
			});
		});

		if( typeof lektor !== 'undefined' && lektor !== '' ){
			$( '.lektor input' ).val( lektor ).prop( 'readonly', true );
		}
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'kontakt': {
      init: function() {
        // JavaScript to be fired on the contact page
        function init() {
            var width = jQuery(window).width();

            if(width <= 1024) {
              is_mobile = false;
            } else {
              is_mobile = true;
            }

            // Basic options for a simple Google Map
            // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
            var mapOptions = {
                // How zoomed in you want the map to start at (always required)
                zoom: 15,
                draggable: is_mobile,
                scrollwheel: false,
                // The latitude and longitude to center the map (always required)
                center: new google.maps.LatLng(54.459410, 16.990371), // Prof. E.Romera 1/14,76-200 Słupsk, Poland

                // How you would like to style the map.
                // This is where you would paste any style found on Snazzy Maps.
                styles: [{"featureType":"water","elementType":"geometry","stylers":[{"color":"#e9e9e9"},{"lightness":17}]},{"featureType":"landscape","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":20}]},{"featureType":"road.highway","elementType":"geometry.fill","stylers":[{"color":"#ffffff"},{"lightness":17}]},{"featureType":"road.highway","elementType":"geometry.stroke","stylers":[{"color":"#ffffff"},{"lightness":29},{"weight":0.2}]},{"featureType":"road.arterial","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":18}]},{"featureType":"road.local","elementType":"geometry","stylers":[{"color":"#ffffff"},{"lightness":16}]},{"featureType":"poi","elementType":"geometry","stylers":[{"color":"#f5f5f5"},{"lightness":21}]},{"featureType":"poi.park","elementType":"geometry","stylers":[{"color":"#dedede"},{"lightness":21}]},{"elementType":"labels.text.stroke","stylers":[{"visibility":"on"},{"color":"#ffffff"},{"lightness":16}]},{"elementType":"labels.text.fill","stylers":[{"saturation":36},{"color":"#333333"},{"lightness":40}]},{"elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"geometry","stylers":[{"color":"#f2f2f2"},{"lightness":19}]},{"featureType":"administrative","elementType":"geometry.fill","stylers":[{"color":"#fefefe"},{"lightness":20}]},{"featureType":"administrative","elementType":"geometry.stroke","stylers":[{"color":"#fefefe"},{"lightness":17},{"weight":1.2}]}]
            };

            // Get the HTML DOM element that will contain your map
            // We are using a div with id="map" seen below in the <body>
            var mapElement = document.getElementById('map');

            // Create the Google Map using our element and options defined above
            var map = new google.maps.Map(mapElement, mapOptions);

            // Let's also add a marker while we're at it

            // TODO: podmienić ścieżkę docelowa do obrazka dla pointera!!
            var marker = new google.maps.Marker({
                position: new google.maps.LatLng(54.459410, 16.990371),
                map: map,
                icon: "http://nano.dev-insanelab.com/mappoint.png",
                title: 'Prof. E.Romera 1/14,76-200 Słupsk, Poland'
            });
        }

        // When the window has finished loading create our google map below
        google.maps.event.addDomListener(window, 'load', init);
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
